import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CapitalizePipe} from './capitalize.pipe';
import {DateFormatPipe} from './date-format.pipe';
import {NumberWithCommasPipe} from './number-with-commas.pipe';
import {PluralPipe} from './plural.pipe';
import {RoundPipe} from './round.pipe';
import {TimingPipe} from './timing.pipe';


const PIPES = [
	CapitalizePipe,
	PluralPipe,
	RoundPipe,
	TimingPipe,
	NumberWithCommasPipe,
	DateFormatPipe,
];

@NgModule({
	declarations: PIPES,
	imports: [
		CommonModule,
	],
	exports: PIPES,
})
export class PipesModule {
}
