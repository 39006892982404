import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './@core/utils/auth-guard.service';

export const routes: Routes = [
	{
		path: 'pages',
		canActivate: [AuthGuardService],
		loadChildren: () => import('./pages/pages.module')
			.then(m => m.PagesModule),
	},
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module')
			.then(m => m.NgxAuthModule),
	},
	{
		path: 'external',
		loadChildren: () => import('./external/external.module')
			.then(m => m.ExternalModule),
	},
	{path: '', redirectTo: 'pages', pathMatch: 'full'},
	{path: '**', redirectTo: 'pages'},
];

const config: ExtraOptions = {
	useHash: false,
};

@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
