import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {NbAuthService} from '@nebular/auth';
import {tap} from 'rxjs/operators';
import {SessionService} from './session.service';

@Injectable()
export class AuthGuardService implements CanActivate {

	constructor(private authService: NbAuthService, private router: Router, private sessionService: SessionService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (route && route.queryParams && route.queryParams['restaurantId']) {
			this.sessionService.setRestaurant({'id': route.queryParams['restaurantId']});
		} else if (route && route.queryParams && route.queryParams['resto']) {
			this.sessionService.setRestaurant({'id': route.queryParams['resto']});
		}

		return this.authService.isAuthenticated()
			.pipe(
				tap(authenticated => {
					if (!authenticated) {
						if (state.url !== '/auth/login') {
							SessionService.setWantedUrl(state.url);
						}
						this.router.navigate(['auth/login']);
					}
				}),
			);
	}
}
