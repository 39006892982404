import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {NbAuthService} from '@nebular/auth';
import {tap} from 'rxjs/operators';
import {SessionService} from './session.service';

@Injectable()
export class UnauthGuardService implements CanActivate {

	constructor(private authService: NbAuthService, private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.authService.isAuthenticated()
			.pipe(
				tap(authenticated => {
					if (authenticated) {
						this.router.navigate([SessionService.getWantedUrl()]);
					}
				}),
			);
	}
}
