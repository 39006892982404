import {Injectable} from '@angular/core';
import {Observable, of as observableOf} from 'rxjs';
import {OrdersChart, OrdersChartData} from '../data/orders-chart';
import {OrderProfitChartSummary, OrdersProfitChartData} from '../data/orders-profit-chart';
import {ProfitChart, ProfitChartData} from '../data/profit-chart';

@Injectable()
export class OrdersProfitChartService extends OrdersProfitChartData {

	private summary = [
		{
			title: 'Marketplace',
			value: 3654,
		},
		{
			title: 'Last Month',
			value: 946,
		},
		{
			title: 'Last Week',
			value: 654,
		},
		{
			title: 'Today',
			value: 230,
		},
	];

	constructor(private ordersChartService: OrdersChartData,
	            private profitChartService: ProfitChartData) {
		super();
	}

	getOrderProfitChartSummary(): Observable<OrderProfitChartSummary[]> {
		return observableOf(this.summary);
	}

	getOrdersChartData(period: string): Observable<OrdersChart> {
		return observableOf(this.ordersChartService.getOrdersChartData(period));
	}

	getProfitChartData(period: string): Observable<ProfitChart> {
		return observableOf(this.profitChartService.getProfitChartData(period));
	}
}
