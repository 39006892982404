import {Component, OnInit, ViewChild} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {VersionService} from '../../../shared/version.service';

@Component({
	selector: 'ngx-footer',
	styleUrls: ['./footer.component.scss'],
	template: `
		<span class="created-by">
      Created with ♥ by le 17.45 - {{ getVersion() }}
    </span>
		<div class="socials">
			<ngx-lang-picker></ngx-lang-picker>
			<a href="https://facebook.com/le17.45" target="_blank" class="ion ion-social-facebook"></a>
			<a href="mailto:contact@le17-45.fr" target="_blank" class="ion ion-android-mail"></a>
			<!-- <a href="#" target="_blank" class="ion ion-social-linkedin"></a> -->
		</div>
		<ng-template #dialog let-data let-ref="dialogRef">
			<nb-card>
				<nb-card-header>Nouvelle version disponible !</nb-card-header>
				<nb-card-body>Nous venons de déployer une nouvelle version de l'application, veuillez recharger votre
				              page.
				</nb-card-body>
				<nb-card-footer>
					<button nbButton (click)="ref.close()" status="success">Recharger</button>
				</nb-card-footer>
			</nb-card>
		</ng-template>
	`,
})
export class FooterComponent implements OnInit {
	public version: string;
	@ViewChild('dialog') dialog;

	constructor(private dialogService: NbDialogService,
	            private versionService: VersionService) {
	}

	public ngOnInit(): void {
		this.versionService.getVersion().subscribe((data) => {
			this.version = data.version;
			setInterval(() => {
				this.versionService.getVersion().subscribe((newData) => {
					const newVersion = newData.version;
					if (this.version !== newVersion) {
						const modale = this.dialogService.open(this.dialog);
						modale.onClose.subscribe(e => {
							window.location.reload();
						});
						this.version = newVersion;
					}
				});
			}, 10000);
		});

	}

	public getVersion(): any {
		return this.version;
	}
}
