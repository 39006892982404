<div class="header-container">
	<div class="logo-container">
		<a (click)="toggleSidebar()" class="sidebar-toggle" href="#">
			<nb-icon icon="menu-2-outline"></nb-icon>
		</a>
		<a (click)="navigateHome()" class="logo" href="#">
			<img alt="GROIN" class="d-none d-md-block" src="/assets/logo-groin.png" style="max-width: 100%;">
			<img alt="GROIN" class="d-block d-md-none" src="/assets/logo-groin-sm.png" style="max-width: 100%;">
		</a>
	</div>
</div>

<div class="header-container">
	<nb-select (selectedChange)="changeRestaurant($event)" *ngIf="enableRestaurantPicker()" [(selected)]="currentRestaurant" placeholder="{{ 'Choisir un restaurant' | translate}}" status="primary">
		<nb-option *ngFor="let restaurant of restaurants" [value]="restaurant">{{ restaurant.nom_court }}</nb-option>
	</nb-select>
	<div style="width: 30px;text-align: center">
		<i (click)="changeTheme('dark')" *ngIf="'dark' !== currentTheme" class="fa fa-lightbulb"></i>
		<i (click)="changeTheme('default')" *ngIf="'default' !== currentTheme" class="fa fa-lightbulb-on"></i>
	</div>
	<nb-actions size="medium">
		<nb-action *nbIsGranted="['view', 'user']" class="user-action">
			<nb-user [name]="user?.firstname + ' '+user?.lastname"
			         [nbContextMenu]="userMenu"
			         [onlyPicture]="userPictureOnly"
			         [picture]="'https://api.groin.le17-45.fr/user_pic?id='+user.id">
			</nb-user>
		</nb-action>
	</nb-actions>
</div>

<ng-template #crop let-data let-ref="dialogRef">
	<nb-card>
		<nb-card-header>Photo de profil</nb-card-header>
		<nb-card-body>
			<image-cropper
				(cropperReady)="cropperReady()"
				(imageCropped)="imageCropped($event)"
				(imageLoaded)="imageLoaded($event)"
				(loadImageFailed)="loadImageFailed()"
				[aspectRatio]="1"
				[imageChangedEvent]="imageChangedEvent"
				[maintainAspectRatio]="true"
				[onlyScaleDown]="true"
				format="jpeg"
				imageQuality="80"
				resizeToWidth="512"
				style="max-height:50vh"
			></image-cropper>
		</nb-card-body>
		<nb-card-footer>
			<button (click)="ref.close()" nbButton status="success">Annuler</button>
			<button (click)="uploadProfilePic(ref)" nbButton status="success">Mettre en ligne</button>
		</nb-card-footer>
	</nb-card>
</ng-template>
<input #fileInput (change)="onFileSelected($event)" style="display:none;" type="file"/>
