import {CommonModule} from '@angular/common';
import {HttpResponse} from '@angular/common/http';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {
	getDeepFromObject,
	NbAuthJWTToken,
	NbAuthModule,
	NbPasswordAuthStrategy,
	NbPasswordAuthStrategyOptions,
} from '@nebular/auth';
import {NbRoleProvider, NbSecurityModule} from '@nebular/security';
import {of as observableOf} from 'rxjs';
import {environment} from '../../environments/environment';
import {CountryOrderData} from './data/country-order';
import {EarningData} from './data/earning';
import {ElectricityData} from './data/electricity';
import {OrdersChartData} from './data/orders-chart';
import {OrdersProfitChartData} from './data/orders-profit-chart';
import {ProfitBarAnimationChartData} from './data/profit-bar-animation-chart';
import {ProfitChartData} from './data/profit-chart';
import {SecurityCamerasData} from './data/security-cameras';
import {SmartTableData} from './data/smart-table';
import {SolarData} from './data/solar';
import {StatsBarData} from './data/stats-bar';
import {StatsProgressBarData} from './data/stats-progress-bar';
import {TemperatureHumidityData} from './data/temperature-humidity';
import {TrafficBarData} from './data/traffic-bar';
import {TrafficChartData} from './data/traffic-chart';
import {TrafficListData} from './data/traffic-list';
import {UserActivityData} from './data/user-activity';
import {UserData} from './data/users';
import {VisitorsAnalyticsData} from './data/visitors-analytics';
import {CountryOrderService} from './mock/country-order.service';
import {EarningService} from './mock/earning.service';
import {ElectricityService} from './mock/electricity.service';
import {MockDataModule} from './mock/mock-data.module';
import {OrdersChartService} from './mock/orders-chart.service';
import {OrdersProfitChartService} from './mock/orders-profit-chart.service';
import {ProfitBarAnimationChartService} from './mock/profit-bar-animation-chart.service';
import {ProfitChartService} from './mock/profit-chart.service';
import {SecurityCamerasService} from './mock/security-cameras.service';
import {SmartTableService} from './mock/smart-table.service';
import {SolarService} from './mock/solar.service';
import {StatsBarService} from './mock/stats-bar.service';
import {StatsProgressBarService} from './mock/stats-progress-bar.service';
import {TemperatureHumidityService} from './mock/temperature-humidity.service';
import {TrafficBarService} from './mock/traffic-bar.service';
import {TrafficChartService} from './mock/traffic-chart.service';
import {TrafficListService} from './mock/traffic-list.service';
import {UserActivityService} from './mock/user-activity.service';

import {UserService} from './mock/users.service';
import {VisitorsAnalyticsService} from './mock/visitors-analytics.service';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {AnalyticsService, LayoutService, PlayerService, SeoService, StateService} from './utils';
import {ApiService} from './utils/api.service';
import {SessionService} from './utils/session.service';

const socialLinks = [
	{
		url: 'https://github.com/akveo/nebular',
		target: '_blank',
		icon: 'github',
	},
	{
		url: 'https://www.facebook.com/akveo/',
		target: '_blank',
		icon: 'facebook',
	},
	{
		url: 'https://twitter.com/akveo_inc',
		target: '_blank',
		icon: 'twitter',
	},
];

const DATA_SERVICES = [
	{provide: UserData, useClass: UserService},
	{provide: ElectricityData, useClass: ElectricityService},
	{provide: SmartTableData, useClass: SmartTableService},
	{provide: UserActivityData, useClass: UserActivityService},
	{provide: OrdersChartData, useClass: OrdersChartService},
	{provide: ProfitChartData, useClass: ProfitChartService},
	{provide: TrafficListData, useClass: TrafficListService},
	{provide: EarningData, useClass: EarningService},
	{provide: OrdersProfitChartData, useClass: OrdersProfitChartService},
	{provide: TrafficBarData, useClass: TrafficBarService},
	{provide: ProfitBarAnimationChartData, useClass: ProfitBarAnimationChartService},
	{provide: TemperatureHumidityData, useClass: TemperatureHumidityService},
	{provide: SolarData, useClass: SolarService},
	{provide: TrafficChartData, useClass: TrafficChartService},
	{provide: StatsBarData, useClass: StatsBarService},
	{provide: CountryOrderData, useClass: CountryOrderService},
	{provide: StatsProgressBarData, useClass: StatsProgressBarService},
	{provide: VisitorsAnalyticsData, useClass: VisitorsAnalyticsService},
	{provide: SecurityCamerasData, useClass: SecurityCamerasService},
];

export class NbSimpleRoleProvider extends NbRoleProvider {
	getRole() {
		// here you could provide any role based on any auth flow
		return observableOf('guest');
	}
}

export const NB_CORE_PROVIDERS = [
	...MockDataModule.forRoot().providers,
	...DATA_SERVICES,
	...NbAuthModule.forRoot({

		strategies: [
			NbPasswordAuthStrategy.setup({
				name: 'email',
				baseEndpoint: environment.apiUrl + '/auth/',
				login: {
					// ...
					redirect: {
						success: SessionService.getWantedUrl(),
					},
					endpoint: 'login',
				},
				register: {
					// ...
					endpoint: 'register',
				},
				logout: {
					method: 'post',
				},
				token: {
					class: NbAuthJWTToken,
					key: 'data.token',
					getter: (module: string, res: HttpResponse<Object>,
					         options: NbPasswordAuthStrategyOptions) => getDeepFromObject(
						res.body,
						'token',
					),
				},
				validation: {
					password: {
						maxLength: null,
						minLength: null,
					},
				},
			}),
		],
	}).providers,

	NbSecurityModule.forRoot({
		accessControl: {
			guest: {
				view: '*',
			},
			user: {
				parent: 'guest',
				create: '*',
				edit: '*',
				remove: '*',
			},
		},
	}).providers,

	{
		provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
	},
	AnalyticsService,
	LayoutService,
	PlayerService,
	SeoService,
	StateService,
	ApiService,
	SessionService,
];

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [
		NbAuthModule,
	],
	declarations: [],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}

	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				...NB_CORE_PROVIDERS,
			],
		};
	}
}
