import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NbAuthJWTToken, NbAuthService, NbTokenService} from '@nebular/auth';
import {
	NbDialogService,
	NbMediaBreakpointsService,
	NbMenuService,
	NbSidebarService,
	NbThemeService,
	NbToastrService,
} from '@nebular/theme';
import {NbMenuItem} from '@nebular/theme/components/menu/menu.service';
import * as Sentry from '@sentry/browser';
import {ImageCroppedEvent, LoadedImage} from 'ngx-image-cropper';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {UserData} from '../../../@core/data/users';
import {LayoutService} from '../../../@core/utils';
import {ApiService} from '../../../@core/utils/api.service';
import {SessionService} from '../../../@core/utils/session.service';


@Component({
	selector: 'ngx-header',
	styleUrls: ['./header.component.scss'],
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
	@ViewChild('fileInput') fileInput;
	@ViewChild('crop') crop;
	userPictureOnly: boolean = false;
	user: any;
	themes = [
		{
			value: 'default',
			name: 'Light',
		},
		{
			value: 'dark',
			name: 'Dark',
		},
	];
	currentTheme = 'default';
	currentRestaurant = null;
	restaurants: any;
	restaurantList = null;
	currentPermission = null;
	userMenu: NbMenuItem[] = [];
	imageChangedEvent: any = '';
	croppedImage: any = '';
	private destroy$: Subject<void> = new Subject<void>();

	constructor(private sessionService: SessionService,
	            private toastrService: NbToastrService,
	            private apiService: ApiService,
	            private sidebarService: NbSidebarService,
	            private menuService: NbMenuService,
	            private themeService: NbThemeService,
	            private tokenService: NbTokenService,
	            private userService: UserData,
	            private layoutService: LayoutService,
	            private router: Router,
	            private dialogService: NbDialogService,
	            private breakpointService: NbMediaBreakpointsService,
	            private authService: NbAuthService) {
		this.authService.onTokenChange()
			.subscribe((token: NbAuthJWTToken) => {
				if (token.isValid() && token.getPayload().data) {
					this.reloadRestaurantList(this.sessionService.getNeededPermissions());
					this.user = token.getPayload().data;
					Sentry.setUser(this.user);
					this.userMenu = [
						{title: 'Déconnexion du connect as', hidden: !this.isConnectedAs()},
						{title: 'Changer ma photo de profil'},
						{title: 'Déconnexion', link: '/auth/logout'},
					];
				}
			});

		this.currentRestaurant = this.sessionService.getRestaurant();
		this.apiService.getRestaurants().subscribe(value => {
			this.restaurantList = value;
			this.reloadRestaurantList(this.sessionService.getNeededPermissions());
		});
		this.sessionService.getPermissionNeededChangeEmitter().subscribe(value => {
			this.reloadRestaurantList(value);
		});
		this.sessionService.getPermissionChangeEmitter().subscribe(() => {
			this.reloadRestaurantList(this.sessionService.getNeededPermissions());
		});
	}

	ngOnInit() {
		this.currentTheme = this.themeService.currentTheme;
		const {xl} = this.breakpointService.getBreakpointsMap();
		this.menuService.onItemClick().subscribe((event) => {
			if (event.item.title === 'Déconnexion du connect as') {
				this.tokenService.set(new NbAuthJWTToken(this.user.previous_token, 'email'));
				this.sessionService.loadPermissions();
				this.reloadRestaurantList(this.sessionService.getNeededPermissions());
				this.router.navigate(['/']);
			}

			if (event.item.title === 'Changer ma photo de profil') {
				this.fileInput.nativeElement.click();
			}
		});
		this.menuService.onItemSelect()
			.pipe(takeUntil(this.destroy$))
			.subscribe((event: { tag: string, item: any }) => {
				this.sidebarService.toggle(true, 'menu-sidebar');

			});

		this.themeService.onMediaQueryChange()
			.pipe(
				map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
				takeUntil(this.destroy$),
			)
			.subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

		this.themeService.onThemeChange()
			.pipe(
				map(({name}) => name),
				takeUntil(this.destroy$),
			)
			.subscribe(themeName => this.currentTheme = themeName);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	changeTheme(themeName: string) {
		localStorage.setItem('theme', themeName);
		this.themeService.changeTheme(themeName);
	}

	toggleSidebar(): boolean {
		this.sidebarService.toggle(true, 'menu-sidebar');
		this.layoutService.changeLayoutSize();

		return false;
	}

	navigateHome() {
		this.menuService.navigateHome();
		return true;
	}

	changeRestaurant($event: any) {
		if (!$event) {
			this.currentRestaurant = null;
		} else {
			this.currentRestaurant = this.restaurants.filter(resto => {
				return parseInt(resto.id, 10) === parseInt($event.id, 10);
			})[0];
		}
		this.sessionService.setRestaurant(this.currentRestaurant);
	}

	enableRestaurantPicker() {
		return this.restaurants && this.restaurants.length > 0;
	}

	onFileSelected(event: any) {
		this.imageChangedEvent = event;
		this.dialogService.open(this.crop);
	}

	public uploadProfilePic(ref): void {
		this.apiService.addProfilePic({
			image: this.croppedImage,
		}).subscribe(value => {
			ref.close();
			// window.location.reload();
		});
	}

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}

	imageLoaded(image: LoadedImage) {
		// show cropper
	}

	cropperReady() {
		// cropper ready
	}

	loadImageFailed() {
		// show message
	}

	private isConnectedAs(): boolean {
		return this.user && this.user.previous_token;
	}

	private reloadRestaurantList(permissionName) {
		if ((permissionName && permissionName === this.currentPermission) || !this.restaurantList || !this.sessionService.getPermissions() || this.sessionService.getPermissions().length < 1) {
			return;
		}
		this.currentPermission = permissionName;
		let tmpRestaurants = [];
		this.sessionService.getPermissions().filter(obj => {
			if (!permissionName || obj[permissionName] === 1) {
				tmpRestaurants = tmpRestaurants.concat(this.restaurantList.filter(resto => {
					return resto.id === obj['restaurant_id'];
				}));
			}
			return obj[permissionName] === 1;
		});
		tmpRestaurants = tmpRestaurants.sort((a, b) => {
			return a['nom_court'] < b['nom_court'] ? -1 : 1;
		});
		this.restaurants = tmpRestaurants;
		// If only one restaurant, choose it directly
		if (this.restaurants.length === 1) {
			this.changeRestaurant(this.restaurants[0]);
			return;
		}

		// If old restaurant not available, reset it
		if (this.restaurants.filter(resto => {
			if (!this.currentRestaurant) {
				return false;
			}
			return parseInt(resto.id, 10) === parseInt(this.currentRestaurant.id, 10);
		}).length === 0) {
			this.changeRestaurant(null);
		} else {
			this.changeRestaurant(this.currentRestaurant);
		}
	}
}
